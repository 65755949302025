@import "../../../styles/variables.less";

.floating-action-button-component {
  position: absolute;
  display: flex;
  align-items: center;
  place-content: center;

  margin: 0;

  height: 50px;
  width: 50px;
  border-radius: 50%;
  font-size: 1.875rem !important;

  background: @primary-color;
  color: @white-color;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);

  &:hover,
  &:focus {
    color: @white-color;
  }

  &.right-bottom {
    bottom: 6px;
  }

  &.right-top {
    bottom: 0px;
  }

  &.right-bottom,
  &.right-top {
    right: 6px;

    &.num-2 {
      right: 54px;
    }

    &.num-3 {
      right: 108px;
    }

    &.num-4 {
      right: 162px;
    }
  }
}
