@import '../../styles/variables.less';

#user-info-component {
  display: flex;
  align-items: center;
  font-size: 1rem;

  .user-dropdown-trigger {
    color: @black-color;
    font-size: 1rem;
    letter-spacing: 0.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    cursor: pointer;
    span {
      margin-right: 16px;
    }


    .ant-avatar {
      width: 32px !important;
      height: 32px !important;
      margin-right: 16px;
    }
  }
}

.info-modal{
  .ant-modal {
    width: 90rem !important;
  }

  #info {
    .ant-carousel .slick-dots li button {
      background:@dark-gray-color;
      width: 8px;
      height: 8px;
      border-radius: 4px;
    }

    .ant-carousel .slick-dots li.slick-active button {
      background: @primary-color;
    }

    .ant-carousel .slick-prev::before {
      z-index: 9999;
      content: '←';
      color: @primary-color;
      display: block;
      position: relative;
      right: 3px;
      bottom: 20px;
      font-size: 40px;
      background-color: lighten(@primary-color, 50%) !important;
      display: flex;
      justify-content: center;
      border-radius: 26px;
      padding: 20px;
      width: 52px;
      height: 52px;
    }

    .ant-carousel .slick-next::before {
      content: '→';
      color: @primary-color;
      display: block;
      position: relative;
      right: 3px;
      bottom: 20px;
      font-size: 40px;
      background-color: lighten(@primary-color, 50%) !important;
      display: flex;
      justify-content: center;
      border-radius: 26px;
      padding: 20px;
      width: 52px;
      height: 52px;
    }

    #info-page {
      .content{
        width: 55rem;
        height: 20rem;
        left: 446.07px;
        top: 139px;

        color: @text-color;
        font-size: 1.2rem;

        h1 {
          font-weight: 700;
        }
      }

      .content-img {
        width: 45rem;
        margin-left: 56px;
      }

      .info-page-img {
        img {
          width: 221.07px;
          height: 330.55px;
          left: 169px;
          top: 116.45px;
        }
      }
    }
  }
}

.user-dropdown {
  min-width: 266px !important;
  width: 266px !important;
  top: 50px !important;

  padding-top: 8px;

  .user-profile {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 14px 20px 0 20px;

    > div {
      position: relative;
    }

    section {
      h3 {
        font-size: 1rem;
        font-weight: 600;
        text-align: left;
        margin-bottom: 5px;
      }

      span {
        font-size: 0.8rem;
        font-weight: 400;
        line-height: 18px;
      }
    }
  }

  .user-menu {
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background: transparent !important;

    .ant-dropdown-menu-item:hover  {
      background-color: transparent;
    }

    .ant-dropdown-menu {
      width: 100%;
    }

    .dark-mode {
      cursor: default;
    }

    span {
      font-size: 1rem;
    }

    .ant-dropdown-menu-title-content {
      display: inline-flex;
      justify-content: space-between !important;
      align-items: center;
      width: 100%;
    }

    .custom-menu {
      color: @black-color;
      font-size: 1rem;

      &:hover,
      &:active,
      &:focus {
        background-color: transparent;
        color: @primary-color;
      }
    }

    .btn-logout {
      margin-top: 24px;
      min-width: 234px;
    }
  }

  .nav-link-users-container {
    height: auto !important;
    padding: 6px 10px !important;
  }

  .nav-link-users {
    display: flex;
    align-items: center;
    width: 100%;
    margin-left: 10px;

    span {
      color: @text-color;
      font-size: 0.9rem;
      font-weight: 500;
    }
  }

  .ant-divider {
    border-top-color: @border-color;
  }

  .privacy-policies-terms-of-use {
    padding: 14px;

    div {
      display: flex;
      justify-content: center;
      align-items: center;

      a {
        font-size: 0.5rem;
        color: @text-color;
        transition: color 0.2s;

        &:hover {
          color: @primary-color;
        }

        &:last-child {
          padding-left: 15px;
          margin-bottom: 2px;
          position: relative;
        }

        &:last-child::before {
          content: '';
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background: @text-color;
          position: absolute;
          left: 0;
          top: 50%;
          margin-left: 8px;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent !important;
}
