@import '../../../../../../../styles/variables.less';

#blazon-component {
  padding: 0 24px;

  .custom-carousel {
    height: 14rem;
  }

  .ant-carousel {
    .slick-dots li button {
      margin-top: 8px;
      background: @primary-color;
      opacity: 0.4;
      width: 16px;
      height: 16px;
      border-radius: 8px;
    }
  }

  form {
    padding: 0 24px;
    margin-top: 8px;
  }

  .ant-carousel .slick-dots li.slick-active button {
    opacity: 1;
    background: @primary-color;
    width: 24px;
    border-radius: 8px;
  }

  .scroll-container {
    img {
      margin-top: 35px !important;
    }
  }
}
