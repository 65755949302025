#review-component {
  #filter {
    position: sticky;
    width: 310px;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    margin-top: -30px;
    padding-top: 30px;
    overflow-y: auto;
    max-height: calc(100vh - 71px);
    padding-bottom: 63px;
  }

  #list {
    width: 808px;
    flex: 1;
    padding-left: 40px;
  }
}

.multiselect-option {
  i.contract-icon {
    color: #39c560;
    padding: 1px 2px;
    font-size: 0.75rem;
    border: 1px solid #39c560;
    border-radius: 50%;
  }
}

#review-wrapper-component .timeline-button {
  margin-right: 15px;

  &.withoutAnotherUser {
    margin-bottom: 28px !important;
  }
}

#alert-error-evaluated-load {
  width: 100%;
  margin: 30px 0 0 0;
}
