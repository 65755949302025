.open-all-dropdown {
  .ant-dropdown-menu-item {
    padding: 0 !important;

    button {
      width: 100%;
    }
  }
}

.disabled-dropdown:hover {
  color: #bfbfbf;
}
