#specimen-information-component {
  textarea {
    resize: none;
    padding: 8px;
    min-height: 96px;
  }

  .form-item { 
    margin-top: 2rem;
    label { 
      color: rgba(130, 130, 130, 1) !important
    } 
  }
  .doc-refurl-label {
    margin-bottom: 2px;
    font-size: 0.75rem;
  }
}
