@import '../../../../../../../styles/variables.less';

#countdown-component {
  div {
    background: @white-color;
    padding: 5px;
    box-shadow: 0 0 60px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
  }

  span {
    font-size: 1rem;
    padding: 3px 6px 2px 6px;
    border-radius: 4px;
    background-color: @gray-light-color;
  }
}
