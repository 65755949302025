@import "../../../../styles/variables.less";

#queue-empty-component {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 437px;
    height: 273px;
  }

  .btn-queue-empty {
    height: 35px;

    justify-content: center;
    align-items: center;

    a {
      font-weight: 700;
      font-size: 1rem;
      color: @black-color;

      &:hover {
        color: @primary-color;
      }
    }
  }
}
