@import '../../../../../../../styles/variables.less';

#document-identification-component {
  margin-bottom: 14px;

  .document-identification-title {
    background-color: rgba(@primary-color, 10%);
    padding: 4px 8px;
    border-radius: 4px;
    max-height: 26px;

    span {
      color: @primary-color;
      font-size: 0.875rem;
      line-height: 18px;
    }
  }

  .country-info {
    padding: 4px 6px;
    border: 1px solid @gray-light-color-1;
    border-radius: 4px;
    max-height: 32px;

    .country-code {
      margin-left: 0.5rem;
    }

    .spin-loading-flag {
      margin-right: 0.5rem;
    }
  }
}
