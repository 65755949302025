@import '../../../../styles/variables.less';

#operations-groups-page {
  #operations-groups-page-content {
    .custom-page-title {
      max-width: 40%;
    }

    .custom-search-input {
      width: 300px;
    }

    .create-new-group {
      flex: 1;
      max-width: 30%;
    }
  }

  .master-group-icon {
    color: @primary-color !important;
  }

  .ant-dropdown-trigger:hover {
    cursor: pointer;
  }
}
