@import '../../../styles/variables.less';

#home-page {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  #home-page-content {
    width: 100vw;
    max-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px 30px;

    .title {
      text-align: center;
      color: @black-color;
      opacity: 0.75;
      font-size: 32px;
    }

    img {
      width: 23rem;
      height: 23rem;
    }
  }
}
