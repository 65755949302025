@import '../../../../../../../styles/variables.less';

#documents-confirmation-component {
  position: fixed;
  margin: 16px !important;
  width: 64vw;
  font-size: 1rem;
  height: 100vh;
  overflow: auto;

  .img-error {
    position: relative;
    top: 25%;
    left: 25%;
  }

  h1 {
      font-size: 1.1rem;
  }

  > .slick-dots li button {
    width: 6px;
    height: 6px;
    border-radius: 100%;
  }

  > .slick-dots li.slick-active button {
    width: 7px;
    height: 7px;
    border-radius: 100%;
  }

  .image-tabs {
    margin-top: -15px;
  }

  .ant-tabs-tab-btn  {
    font-size: 1rem;
    font-weight: normal;
  }

  .custom-tooltip {
    white-space: nowrap;
  }

  .documents-confirmation-container {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    gap: 0 16px;
  }
}
