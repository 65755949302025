@import '../../../../styles/variables.less';

#operations-groups-users-list-component {
  .title {
    margin-bottom: 4px;
  }

  .subtitle {
    font-size: 1rem;
  }

  .operations-group-select {
    &:hover {
      .ant-select-arrow {
        &:not(:last-child) {
          display: none;
        }
      }
    }

    .ant-select-selector {
      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        font-weight: 400;
        font-size: 1rem;
      }
    }

    .ant-select-arrow {
      svg {
        width: 24px;
        height: 24px;
        margin: 0px;
      }
    }

    .ant-select-clear {
      background: #F4F4F4;
      width: 16px;
      height: 16px;
      top: 40%;
      right: 15px;

      svg {
        color: @danger-color !important;
        width: 16px;
        height: 16px;
        margin: 0px;
      }
    }
  }
}
