@import '../../../../../../../styles/variables.less';

#international-documents-confirmation-component {
  @carousel-height: 112px;

  height: 100vh;
  max-height: 100vh;
  width: 64vw;
  margin-top: 12px;
  margin-left: 16px;
  gap: 12px;

  .ant-tabs-nav {
    display: none;
  }

  .specimen-column {
    height: 100%;

    .tabs-zoomed-images,
    .ant-tabs-content,
    .specimen-document-zoomed-image-component {
      max-height: calc(90vh);
    }

    .ant-carousel {
      height: @carousel-height;
    }
  }

  .specimen-backpanel {
    background-color: white;
    border-radius: 8px;
    margin-top: 1rem;
    padding: 1rem;
  }
  .review-document-zoomed-image-component {
    .zoomed-image-label {
      background-color: rgba(@primary-color, 75%);
    }
  }

  .specimen-documents-image-carousel {
    background-color: rgba(@black-color, 40%);
    border-radius: 0px 0px 8px 8px;
    padding: 0px 8px 12px 8px;
    height: @carousel-height;
    width: calc(32vw - 16px);
    position: absolute;
    bottom: @carousel-height;
    margin-bottom: 0px;

    .slick-list {
      width: 100%;

      .specimen-carousel-item {
        button {
          border: 0;
          border-radius: 4px;
          padding: 0px;
          cursor: pointer;

          img {
            width: 100px;
            height: 88px;
            border: 2px solid transparent;
            border-radius: 4px;
            padding: 0px;
          }

          .specimen-carousel-item-label {
            position: absolute;
            bottom: 0;
            background-color: rgba(@dark-color, 40%);
            width: 88px;
            height: 20px;
            font-size: 0.75rem;
            font-weight: 500;
            color: @white-color;
            padding: 4px 8px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            margin: 0px 4px 6px 6px;
            border-radius: 2px;
          }

          &.specimen-is-selected {
            img {
              border-color: @primary-color;
            }

            .specimen-carousel-item-label {
              background-color: @primary-color;
            }
          }
        }
      }
    }

    .slick-prev, .slick-next {
      color: unset;
      font-size: unset;
      z-index: 999;

      &.slick-disabled {
        opacity: 0;
        cursor: default;
      }
    }

    .slick-prev {
      margin-left: 8px;
    }

    .slick-next {
      margin-right: 8px;
    }

    .slick-dots li button {
      margin-top: 16px;
      background: @white-color;
      opacity: 0.4;
      width: 14px;
      height: 4px;
      border-radius: 8px;
    }

    .slick-dots li.slick-active button {
      background: @dark-gray-color;
      opacity: 1;
      width: 24px;
      border-radius: 8px;
    }
  }

  .img-error {
    position: relative;
    top: 25%;
    left: 25%;
  }

  img {
    pointer-events: none;
  }
}

.specimen-carousel-item-tooltip {
  padding-bottom: 5px;

  .ant-tooltip-content {
    .ant-tooltip-arrow {
      .ant-tooltip-arrow-content::before {
        background-color: rgba(@dark-color);
        --antd-arrow-background-color: rgba(@dark-color);
      }
    }

    .ant-tooltip-inner {
      background-color: rgba(@dark-color);
    }
  }
}
