.toast-message {
  .ant-message-custom-content {
    display: flex;
    align-items: center !important;
    justify-content: center !important;

    svg {
      margin-right: 8px;
    }
  }
}
