#queue-component {

  #queue-subheader-component,
  #queue-cards-component {
    margin-bottom: 2.5rem;
  }

  hr {
    margin: 0;
  }

  #queue-list-component {
    margin-top: 2.5rem;
  }
}
