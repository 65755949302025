@import '../../../../../../../styles/variables.less';

#ocr-details-component {
  padding: 24px 30px;

  form {
    label {
      color: @dark-gray-color;
    }

    .custom-inputs {
      display: flex;
      gap: 8px;
      div {
        flex: 1;
      }
    }

    .ant-form-item-has-error {
      .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input{
        background-color: transparent;
      }

      .ant-form-item-explain-error {
        margin-top: 2rem;
      }

      .ant-form-item-explain, .ant-form-item-explain-connected{
        margin-top: -2.3rem;
      }

      .btn-confirm {
        span {
          color: @primary-color !important;
        }

        &:hover,
        &:active,
        &:focus {
          span {
            color: @white-color !important;
          }
        }
      }
    }

    .ant-form-item-control-input-content {
      .custom-input {
        border-radius: 8px;
        border: 1px solid #d9d9d9;
      }
    }

    input {
      font-size: 1rem;
      height: 32px;
    }

    .btn-confirm {
      width: 109px;
    }
  }
}
