#queue-subheader-component {
  .custom-page-title {
    .title {
      font-size: 2rem;
      font-weight: 700;
      margin-bottom: 0.25rem;
    }

    .subtitle {
      font-size: 1rem;
    }
  }

  .queue-query {
    .last-update {
      font-size: 1rem;
      margin-right: 1rem;
    }

    .btn-custom-secondary {
      font-weight: 700;
    }
  }
}
