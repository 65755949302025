@import '../../../../../../../../../../styles/variables.less';

.view-selfie-modal-component {
  .ant-modal {
    width: 1090px !important;

    .ant-modal-body {
      padding: 24px 48px;
    }

    .modal-body {
      height: 690px;

      .zoomed-image-selfie {
        height: 100% !important;
      }
    }
  }
}
