@import "../../../styles/variables.less";

.gx-main-content-wrapper {
  padding-top: 24px;
}

.tabs {
  margin-bottom: 24px;

  a {
    color: @gray-color;

    &.active {
      color: @black-color;
    }

    font-size: 1rem;
    padding-bottom: 0px;
    font-weight: 700;

    & + a {
      margin-left: 25px;
    }
  }

  .active {
    color: @text-color;
    border-bottom: 2px solid @primary-color;
  }

  .custom-caf-icon {
    margin-right: 6px;
  }
}

#operations-groups-list-table,
#operations-groups-users-list-table {
  .ant-table-thead {
    .ant-table-cell {
      font-size: 1rem;
      font-weight: 700;
    }
  }
}
