@import '../../../../../../../styles/variables.less';

.documents-header-component {
  gap: 18px;
  font-size: 1rem;

  .ant-collapse {
    margin-left: 0px !important;
    width: 100%;

    &.show-documents-header-buttons {
      width: 450px;
    }

    .ant-collapse-header {
      span {
        font-size: 1rem;
        font-weight: 500;
        max-width: 50rem;
      }
    }

    .rule-tip {
      display: flex;
      height: 1.625rem;
      margin: 8px;

      .tip-content {
        color: @white-color;
        background-color: @primary-color;
        padding: 4px 8px 4px 6px;
        border-radius: 12px;
        font-size: 1rem;
        font-weight: 500;
        max-width: 100%;

        .white-dot {
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background-color: @white-color;
          margin: auto 0.25rem auto 0.25rem;
        }

        .tip-text {
          display: inline-block;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: max-content;
        }
      }
    }
  }
}
