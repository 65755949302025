@import '../../styles/variables.less';

.zoomed-image-component,
#pan-zoomed-image-component {
  overflow: visible;
  border-radius: 10px;
  border: 1px solid @border-color;
  background-color: #f9f9f9;
  position: relative;

  &:hover {
    .zoomed-controls {
      display: block;
    }
  }

  .zoomed-controls {
    position: relative;
    display: none;

    button {
      width: 40px !important;
      height: 40px !important;
      padding: 8px !important;
      margin: 10px !important;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;

      .custom-caf-icon {
        div {
          width: 24px !important;
          height: 24px !important;
        }
      }


      &.active {
        background-color: @primary-color;
        opacity: 1;
      }
    }
  }

  .zoomed-image-label {
    position: absolute;
    background-color: rgba(@dark-color, 40%);
    left: 0;
    top: 0;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    z-index: 9;
    margin: 8px 0px 0px 8px;

    span {
      font-size: 0.75rem;
      font-weight: 400;
      color: #fff;
    }
  }

  .zoomed-image-menu {
    position: absolute;
    background-color: rgba(@dark-color, 40%);
    border-color: transparent;
    right: 0;
    top: 0;
    height: 24px;
    min-width: 40px !important;
    max-width: 140px !important;
    font-weight: 500;
    color: black !important;
    padding: 0;
    border-radius: 4px;
    z-index: 9;
    margin: 8px 8px 0px 0px;

    .ant-menu-submenu {
      margin-left: -1px;

      .ant-menu-submenu-title {
        height: 24px;
        font-weight: normal;
        font-size: 0.75rem;
        border-radius: 4px;
        padding: 0px 12px !important;

        color: @white-color;

        .ant-menu-submenu-arrow {
          &:after,
          &:before {
            background: @white-color;
          }
        }
      }

      .ant-menu-sub {
        margin-top: 4px;

        .zoomed-image-menu-item {
          border-radius: 4px !important;
          padding: 0px 12px !important;
          height: 35px;

          .ant-menu-title-content {
            color: @dark-gray-color;

            &:hover {
              color: @primary-color;
              font-weight: 700;
            }
          }

          &:hover {
            background-color: rgba(@primary-color, 8%);
          }

          &:after {
            border: none;
          }
        }
      }
    }

    span {
      font-size: 0.75rem;
    }
  }

  .error {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 100%;

    >div {
      flex: 1;
    }
  }

  .left-bottom,
  .right-bottom {
    background-color: #fff;

    i {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;

    .ant-spin {
      margin-right: 0;
      margin-bottom: 0;
    }

    .ant-spin-text {
      color: @text-color;
      opacity: 0.5;
      font-weight: 300;
    }
  }
}
