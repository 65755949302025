@import '../../../../../../../styles/variables.less';

#header-data-component {
  padding: 24px 20px;

  form {
    padding: 0 20px;
    width: 100%;
    .label {
      margin-left: -10px;
      color: @black-color;
    }

    input {
      width: 20rem;
    }

    span, label, input {
    font-size: 0.9rem;
    }

    .row-buttons {
      display: flex;
      justify-content: right;
    }
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .ant-btn {
      padding: 0 !important;
      font-size: 1rem;
    }
  }
}
