@import '../../../../../../../styles/variables.less';

#condition-image-component {
  padding: 24px;
  .img-condition {
    width: 347px;
  }

  form {
    width: 100%;
    padding: 0 30px;
    .label {
      max-width: 140px;
      margin-top: 10px;
      color: @black-color;
    }
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .ant-btn {
      padding: 0 !important;
      font-size: 1rem;
    }
  }
}
