body {
  color: @black-color !important;
}

::-webkit-scrollbar-track {
  background-color: #f4f4f4;
  border-radius: 3px;
}

::-webkit-scrollbar {
  width: 4px;
  background: #f4f4f4;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  width: 4px;
  background: #575757;
  border-radius: 3px;
}

body,
button {
  letter-spacing: 0.4px;
}

@media (max-width: 1900px) {
  html {
    font-size: 100%;
    width: 100%;
  }
}

@media (max-width: 1370px) {
  html {
    font-size: 90%;
    width: 100%;
  }
}

.gx-main-content-wrapper,
.gx-container {
  min-width: 46rem;
}

.ant-layout > .gx-layout-content {
  overflow-x: auto;
}

[class^='caf-'],
[class*=' caf-'] {
  font-size: 1rem;
  line-height: 1;
}

[class^='caf-']:before,
[class*=' caf-']:before {
  margin: 0 !important;
}

.ant-switch {
  i[class^='caf-'],
  i[class*=' caf-'] {
    font-size: 1rem;
    display: flex;
  }
}

#page-loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-select-multiple .ant-select-selection-search-input {
  height: 1rem !important;
}

.ant-dropdown-menu-title-content {
  display: flex;
  align-items: center;
}

.ant-select-selection-overflow-item {
  .custom-caf-icon {
    svg {
      width: 12px;
    }
  }
}

.zoomed-image-menu-item {
  color: @black-color !important;
}

.zoomed-image-menu-item:hover {
  background-color: @gray-light-color-1;
}

.zoomed-image-submenu,
.zoomed-image-submenu > .ant-menu {
  border-radius: 4px !important;
}

.custom-search-input,
.custom-input {
  background: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none !important;
  padding-top: 0;
  padding-bottom: 0;

  input {
    background: transparent;
    border-radius: 0;
    height: 2.1rem;
    font-size: 1rem;
    margin-left: 18px;
  }

  svg {
    width: 24px;
    height: 24px;
  }

  .ant-select-selection-placeholder {
    font-size: 1rem;
    color: @dark-gray-color;
  }

  &:focus-within {
    border-bottom-color: @primary-color;
  }

  .ant-input-prefix {
    margin-right: 0.6rem;
  }
}

.custom-search-input {
  min-width: 14.3rem;
}

.custom-input {
  padding-top: 5px;
  padding-bottom: 3px;
}

.custom-select-input,
.select-custom-icon,
.custom-search-input {
  border-radius: 0 !important;
  border-bottom: 1px solid @gray-light-color-1 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;

  .ant-select-selection-item {
    padding-right: 1.5rem !important;
  }

  .ant-select-arrow {
    margin-left: 4px;

    svg {
      color: @black-color !important;
    }
  }
}

.uppercase-form {
  .custom-input {
    text-transform: uppercase;
  }
}

.page-title {
  .title {
    font-size: 2rem;
    margin-bottom: 0;
    font-weight: 900;
  }

  .subtitle {
    color: @text-color-secondary;
  }
}

.text-dark {
  color: @text-color !important;
}

.text-danger {
  color: @danger-color;
}

.text-default {
  color: @text-color !important;
}

.text-secondary {
  color: @text-color-secondary !important;
}

.message-top {
  margin-top: 44px;
}

.btn-custom-primary {
  font-size: 1rem;
  font-weight: 700;
  color: @white-color !important;
  background-color: @primary-color;
  text-shadow: none;
  border: none;
  padding: 0 20px;

  &:hover,
  &:active,
  &:focus {
    background-color: @secondary-color;
    color: @white-color;
  }
}

.btn-custom-secondary {
  font-size: 1rem;
  font-weight: 500;
  color: @primary-color;
  border-color: @primary-color;
  background-color: transparent;
  text-shadow: none;
  padding: 0 16px !important;

  &:hover,
  &:active,
  &:focus {
    background-color: @primary-color !important;
    color: @white-color !important;
  }
}

.btn-custom-cancel {
  font-size: 1rem;
  font-weight: 700;
  border-color: @primary-color;
  background-color: transparent;
  text-shadow: none;
  padding: 0 16px !important;
  color: @danger-color;
  border: 1px solid @danger-color;

  &:hover,
  &:active,
  &:focus {
    background-color: @danger-color !important;
    color: @white-color !important;
    border: 1px solid @danger-color !important;
    outline: none;
  }
}

.btn-more-icon {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;

  &:hover {
    background-color: darken(@gray-light-color, 8%);
  }
}

.btn-simple {
  border: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.8;
  }
}

.no-arrow {
  .ant-input-number-handler-wrap {
    display: none !important;
  }
}

input:read-only {
  cursor: default;

  &:focus {
    box-shadow: none;
  }
}

.fd-row {
  flex-direction: row !important;
}

.no-error {
  .ant-form-item-explain {
    animation: none !important;
    display: none !important;
    opacity: 0 !important;
    visibility: hidden !important;
  }
}

.caf-modal {
  .ant-modal-body {
    padding: 2.5rem;
    padding-bottom: 2rem;
  }

  .modal-header {
    margin-bottom: 2rem;
  }

  .modal-title {
    font-size: 1.6rem;
    margin-bottom: 0.3rem;
    font-weight: 900;
  }

  .modal-subtitle {
    font-size: 1rem;
    font-weight: 400;
    color: @text-color-secondary;
  }

  &.offset-timeline {
    right: 23.1rem;
  }
}

a.disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.auth-wrapper {
  .auth-form {
    max-width: 23.75rem;
  }

  .auth-form-content {
    .title {
      font-weight: 900;
      font-size: 2rem;
      margin-bottom: 0.5rem;
    }

    .subtitle {
      font-weight: 300;
      font-size: 1rem;
      display: block;
      margin-bottom: 1rem;
    }

    .ant-form-item {
      margin-bottom: 1rem;
    }
  }

  .auth-right-title {
    color: #fff;
    font-size: 3.1rem;
    font-weight: 900;
    margin-bottom: 0.6rem;
    line-height: 1.15;
  }

  .auth-right-subtitle {
    color: #fff;
    font-size: 1rem;
  }
}

.ant-btn.btn-cancel {
  color: @text-color;
  border-color: @border-color;

  &:hover {
    background-color: @gray-light-color;
    border-color: @border-color;
  }
}

.ant-tag {
  i {
    font-size: 0.75rem;

    &:first-child {
      padding-left: 0;
      padding-right: 6px;
      margin-left: -1px;
      margin-right: 0;
    }

    &:last-child,
    &.icon-right {
      order: 10;
      padding-left: 6px;
      padding-right: 0;
      margin-left: 0;
      margin-right: -1px;
    }
  }
}

.ant-select-selection-item {
  .option-subtitle {
    visibility: hidden;
  }
}

.multiselect-filters {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 0;

  .ant-select-item-option {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ant-select-item-option-state {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);

    > span {
      font-size: 0.8rem;
    }
  }

  .ant-select-item-empty {
    font-size: 0.8rem;
    text-align: center;
    font-style: italic;
    padding: 25px 2rem;
    word-break: break-word;
  }

  .multiselect-option {
    display: flex;
    flex-direction: column;
    padding: 12px 0;

    .option-title {
      font-size: 0.8rem;
      font-weight: 700;
      color: @text-color;
      margin-bottom: 4px;
      line-height: 1.2;
      max-width: 200px;
    }

    .option-subtitle {
      font-size: 0.6rem;
      font-weight: 400;
      font-style: italic;
      color: @text-color;
      line-height: 1.2;
    }
  }
}

.validation-tag {
  border: 1px solid @gray-light-color-1;
  border-radius: 8px;
  padding: 8px 10px;
  display: inline-block !important;
  margin: 5px;
  background-color: @white-color;

  > i {
    color: @black-color;
    margin-right: 5px !important;
  }

  #tag-component {
    display: inline;
    margin-left: 5px;
  }
}

.react-json-view {
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace !important;
  font-size: 0.9em;
  overflow: auto;
}

.border-left {
  border-left: 1px solid @gray-light-color-1;
}

.border-right {
  border-right: 1px solid @gray-light-color-1;
}

#reprove-document-component {
  .ant-select-selection-item {
    background-color: @danger-color;

    span {
      font-size: 1rem;
      color: @white-color;

      i {
        font-size: 0.75rem;
      }
    }
  }

  .ant-select-selection-item {
    height: 24px;
    margin-right: 20px;
    font-size: 0.8rem;
    background-color: @danger-color;
    color: #fff;
    padding-left: 0.75rem;
    padding-right: 0.6rem;
    border-radius: 100px;
    border: none;
    margin-top: 5px;
  }
}

#custom-filter-component {
  .ant-radio-wrapper,
  .ant-checkbox-wrapper {
    width: 100%;
    padding: 7px 0;
    font-weight: 300;
    font-size: 0.8rem;
  }

  .ant-collapse {
    border: none;
    margin-left: -16px;

    .ant-collapse-header {
      font-size: 1rem;
      font-weight: 400;
    }

    .ant-collapse-item {
      border: none;

      .ant-collapse-content {
        border: none;
        background-color: transparent;
        overflow: initial;

        .ant-collapse-content-box {
          padding-top: 0;
        }
      }
    }
  }

  .ant-select-selector {
    border: none;
    background: transparent;
    border-bottom: 1px solid @gray-light-color-1;
    border-radius: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding-top: 4px;
    padding-bottom: 4px;

    .ant-select-selection-overflow
      > .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix {
      min-width: 100%;

      .ant-select-selection-search {
        min-width: 100%;
      }
    }
  }

  .ant-select-multiple {
    border-bottom: 1px solid transparent;
    width: 100%;

    &.ant-select-focused {
      border-bottom: 2px solid @primary-color;

      .ant-select-selector {
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
          0 9px 28px 8px rgba(0, 0, 0, 0.05);
        background: #fff;
        border-bottom-color: @primary-color;
        border: none;
      }

      .ant-select-arrow-loading {
        background: #fff;
      }
    }
  }

  .ant-select-selection-item {
    height: 24px;
    margin-right: 20px;
    font-size: 0.8rem;
    background: @primary-color;
    color: #fff;
    padding-left: 0.75rem;
    padding-right: 0.6rem;
    border-radius: 100px;
    border: none;

    .ant-select-selection-item-content {
      margin-right: 0.5rem;
    }
  }

  .ant-select-selection-item-remove {
    line-height: 1;

    svg {
      color: @white-color;
      width: 12px;
      height: 12px;
    }
  }

  .ant-select-selection-search {
    flex: 1;
    padding-right: 1.3rem;

    input {
      cursor: text;
      font-size: 1rem;
    }
  }

  .ant-select-arrow-loading {
    bottom: 0.6rem;
    width: 1.75rem;
    height: 1.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    top: auto;
    right: 7px;
    color: @text-color;
  }

  .execution-select {
    width: calc(100% - 16px);
    margin-bottom: 1.75rem;

    .ant-select-selector {
      box-shadow: none !important;
      background: transparent !important;
    }
  }
}

#record-details-component {
  .ant-radio-button-wrapper {
    padding: 6px 10px;
    box-shadow: none;
  }

  .ant-radio-button-wrapper::before {
    background-color: transparent;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: @white-color;

    &.correct {
      background-color: @primary-color;
      border-color: @primary-color;
    }

    &.unreadable {
      background-color: @dark-gray-color;
      border-color: @dark-gray-color;
    }

    &.incorrect {
      background-color: @danger-color;
      border-color: @danger-color;
    }
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: transparent;
  }

  .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled, .ant-radio-button-wrapper-checked) {
    color: @black-color;
  }

  & .ant-radio-button-wrapper:last-child {
    border-radius: 0 @border-radius-base @border-radius-base 0 !important;
  }

  & .ant-radio-button-wrapper:first-child {
    border-radius: @border-radius-base 0 0 @border-radius-base;
  }

  .correct {
    &.ant-radio-button-wrapper-disabled {
      background: rgba(@primary-color, 40%);
    }
  }

  .unreadable {
    &.ant-radio-button-wrapper-disabled {
      background: rgba(@dark-gray-color, 40%);
    }
  }

  .incorrect {
    &.ant-radio-button-wrapper-disabled {
      background: rgba(@danger-color, 40%);
    }

    &.ant-radio-button-wrapper-disabled {
      background: rgba(@danger-color, 20%);
    }
  }

  .ant-collapse {
    margin-left: -1rem;

    .ant-collapse-arrow {
      font-size: 0.7rem;
    }

    .ant-collapse-item {
      border: 1px solid @border-color;
      border-radius: @border-radius-base;
    }

    .ant-collapse-item {
      // border: none;

      .ant-collapse-content {
        // border: none;
        background-color: transparent;
        overflow: initial;

        .ant-collapse-content-box {
          padding-top: 0;
        }
      }
    }
  }
}

.ant-select-clear {
  background-color: transparent !important;

  svg {
    color: @gray-color;
    width: 12px;
    height: 12px;
  }
}

.btn-icon {
  border: 1px solid @primary-color;
  border-radius: 16px !important;
  padding: 0;

  &.ant-btn-link {
    &:hover,
    &:focus {
      border-color: @primary-color !important;
    }
  }

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
    color: @primary-color;
  }

  &.ant-dropdown-open {
    background-color: @primary-color !important;
    svg {
      color: @white-color;
    }
  }
}
