.gx-main-content-wrapper {
  padding-bottom: 32px;
}

.ant-select-item {
  color: @heading-color;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: @primary-color;
}

.ant-dropdown, .ant-select-dropdown {
  padding: 4px 0;

  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;

  .ant-menu-vertical {
    border-right: none;
  }

  .ant-dropdown-menu-vertical {
    padding: 0;
    box-shadow: none;
  }

  .ant-dropdown-menu-item {
    padding: 8px 16px;
  }

  .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
    a {
      display: flex;
      align-items: center;
    }
  }

  .ant-dropdown-menu-item:not(.gx-text-danger) {
    svg {
      color: @black-color;
      width: 24px;
      height: 24px;
      margin-right: 4px;
    }
  }

  .ant-dropdown-menu-item.gx-text-danger{
    svg {
      color: @danger-color;
      width: 24px;
      height: 24px;
      margin-right: 4px;
    }

    span {
      color: @danger-color;
    }
  }



  .ant-menu-item,
  .ant-dropdown-menu-item,
  .ant-select-item,
  .ant-picker-cell {
    line-height: 36px;
    color: lighten(@text-color, 10%);


    &.ant-menu-item-disabled,
    .ant-menu-submenu-disabled,
    &.ant-dropdown-menu-item-disabled,
    .ant-dropdown-menu-submenu-disabled {
      color: rgba(0, 0, 0, 0.25) !important;
    }

    &.ant-dropdown-menu-item.ant-dropdown-menu-item-active {
      background-color: transparent !important;
    }

    &.gx-text-danger {
      &.ant-select-item-option-selected {
        background-color: rgba(@danger-color, 0.08);
        .ant-select-item-option-content {
          color: @danger-color !important;
          font-weight: 700;
        }
        svg {
          color: @danger-color !important;
        }
      }
    }

    &:hover:not(.gx-text-danger) {
      background-color: rgba(@primary-color, 0.08);

      .option-title,
      .option-subtitle {
        color: @primary-color !important;
        font-weight: 700;
      }

      span {
        span:not(.dark-mode) {
          color: @primary-color !important;
          font-weight: 700;
        }
        a {
          color: @primary-color !important;
        }

      svg:not(.dark-mode) {
         color: @primary-color !important;
        }
      }

      .ant-select-item-option-content {
        color: @primary-color !important;
        font-weight: 700;
      }

      &.ant-menu-item-disabled,
      .ant-menu-submenu-disabled,
      &.ant-dropdown-menu-item-disabled,
      .ant-dropdown-menu-submenu-disabled {
        background-color: transparent;
      }
    }

    &:hover {
      &.gx-text-danger {
        background-color: rgba(@danger-color, 0.08);

          .ant-select-item-option-content {
           color: @danger-color !important;

          svg {
            color: @danger-color !important;
          }
        }
      }
    }
  }
}

.ant-table-cell{
  svg {
    color: @black-color;
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }
  th.ant-table-cell {
    span.column-title {
      color: @text-color;
      font-size: 16px;
      font-weight: 700;
    }
  }

  .ant-select-arrow {
    svg {
      width: 12px;
      height: 12px;
    }
  }
}

.ant-table-column-sorter {
  display:none;
}

.ant-modal-confirm-body-wrapper .ant-modal-confirm-btns .ant-btn-default:not(.ant-btn-dangerous) {
  background: transparent;
  border: none;

  span {
    color: @danger-color;
  }
}

.ant-btn-primary {
  span {
    color: @white-color; /* Altera a cor da fonte do botão "OK" */
  }
}

.ant-btn-dangerous {
  display: flex;
  align-items: center;
  justify-content: center;

  background: @danger-color;
  width: 65px;

  span {
    color: @white-color;
  }

  &:focus {
    background: @danger-color;
    border-color: @danger-color;
  }

  &:hover {
    background: @danger-secondary-color;
    border-color: @danger-secondary-color;
  }
}

.ant-picker-cell:hover  {
  .ant-picker-cell-inner {
  background-color: rgba(@primary-color, 0.08) !important;
  color: @primary-color !important;
  font-weight: 700;
  }
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background-color: rgba(@primary-color, 0.08) !important;
  color: @primary-color !important;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner  {
    background-color: rgba(@primary-color, 0.08) !important;
    color: @primary-color !important;
    font-weight: 700;
}

.ant-picker-cell-selected{
  .ant-picker-cell-inner {
  background-color: @primary-color !important;
  }
}

.ant-picker-cell-selected:hover{
  .ant-picker-cell-inner {
    color: @white-color !important;
  }
}


.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding-top: 12px;
  padding-bottom: 12px;
  color: #000;
}

.ant-table-tbody > tr > td {
  border-color: @border-color;
}

.ant-table-thead > tr > th {
  font-weight: 300;
  color: lighten(@text-color, 10%);
  background-color: transparent;
  border-bottom-width: 2px;
  border-bottom-color: @border-color;
}

.ant-table {
  background-color: transparent;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: darken(@gray-light-color, 3%);
}

.ant-layout-footer {
  margin-top: 20px;
}

.ant-input,
.ant-input-number {
  border-radius: 8px;
  color: @text-color;
  border-color: @gray-light-color-1;
}

.ant-input-number-handler-wrap {
  border-radius: 0 8px 8px 0;
}

.ant-input-number {
  width: 100%;
  margin-right: 0;
}

.ant-input[disabled] {
  color: lighten(@text-color, 40%) !important;
}

.ant-form-vertical {
  .ant-row {
    flex-direction: row;
    margin-left: -10px;
    margin-right: -10px;

    > [class*='ant-col-'] {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .ant-form-item {
    margin-left: 0;
    margin-right: 0;
    flex-direction: column;
  }

  .ant-form-item-label {
    padding-bottom: 6px;

    > label {
      font-size: 0.75rem;
      color: lighten(@text-color, 30%);
      font-weight: 300;
    }
  }
}

.ant-form-item-label > label.ant-form-item-required::before {
  display: none !important;
}

.ant-btn {
  margin-bottom: 0;
  font-weight: 500;

  &:not(.ant-btn-circle) {
    padding: 2px 10px;
  }
}

.form-inline-button {
  display: flex;
  align-items: flex-end;

  .ant-form-item {
    width: 100%;
  }
}

.ant-divider-horizontal {
  margin: 20px 0;
}

.ant-form-item-explain {
  font-size: 0.75rem;
  padding-top: 4px;
  padding-bottom: 4px;
}

.ant-form-item-extra {
  font-size: 0.75rem;
  font-weight: 300;
}

.ant-select-item-option-content,
.ant-select-selection-item {
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
  }
}

.ant-tag {
  margin: 0;
  border-radius: 100px;
  border: none;
  font-weight: 400;
  line-height: 24px;
  padding: 0px 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 1;
  }
}

.ant-form-item-with-help {
  margin-bottom: 0 !important;
}

.ant-message-custom-content {
  span {
    color: lighten(@text-color, 5%);
    font-weight: 500;
  }
}

.ant-divider {
  border-top-color: @border-color;
}

.ant-modal-confirm-body-wrapper {
  .ant-modal-confirm-body {
    .ant-modal-confirm-title {
      font-size: 1rem;
      font-weight: 400;
    }
  }

  .ant-modal-confirm-btns {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    .ant-btn {
      border-radius: 100px;
      font-size: 1rem;
      font-weight: 700;
    }
  }
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: inherit;
}

.ant-pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .ant-pagination-total-text {
    font-weight: 300;
  }

  .ant-pagination-item-ellipsis {
    font-size: 0.75rem;
  }
}

.custom-footer-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: unset;

  .go-to-form {
    display: inline;

    .new-page-input {
      width: 60px;
      margin: 0;
      display: inline-block;
    }

    .ant-btn-text {
      font-weight: normal;
    }
  }

  .ant-pagination {
    font-size: 0.9rem;
    width: unset;
    > li {
      margin-bottom: 0;
    }
  }
}

.ant-pagination-prev,
.ant-pagination-next {
  .ant-pagination-item-link {
    border-radius: 50%;
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.ant-pagination-disabled {
    .ant-pagination-item-link {
      opacity: 0.3;
    }
  }

  &:not(.ant-pagination-disabled) {
    &:focus {
      .ant-pagination-item-link {
        color: inherit;
      }
    }

    &:hover {
      .ant-pagination-item-link {
        background-color: lighten(@gray-light-color-1, 5%);
        color: @text-color;
      }
    }
  }
}

.ant-pagination-item {
  border-radius: 50%;
  background: transparent;
  border: none;
  font-family: @font-family;
  font-weight: 300;
  margin-right: 6px;

  a {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.ant-pagination-item-active {
    background-color: @primary-color;

    a {
      color: #fff;
      cursor: default;
    }

    &:hover,
    &:focus {
      a {
        color: #fff;
      }
    }
  }

  &:focus {
    a {
      color: inherit;
    }
  }

  &:hover:not(.ant-pagination-item-active) {
    background-color: lighten(@gray-light-color-1, 5%);

    a {
      color: @text-color;
    }
  }
}

.ant-input-affix-wrapper {
  padding: 0;
  overflow: hidden;

  input.ant-input {
    border-radius: 0;
    line-height: 34px;
    padding: 0 11px;

    &.ant-input-sm {
      line-height: 24px;
    }

    &.ant-input-lg {
      line-height: 38px;
      font-size: 0.9rem;
    }

    &:not(:last-child) {
      padding-right: 38px;
    }

    &:not(:first-child) {
      padding-left: 20px;
    }
  }

  i {
    color: @gray-color;
  }

  .ant-input-prefix {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }

  .ant-input-suffix {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    margin-right: 11px;

    svg {
      color: @black-color;
    }
  }
}

.ant-collapse,
.ant-collapse-content,
.ant-collapse > .ant-collapse-item {
  border-color: @border-color;
}

.ant-picker-range {
  input {
    border-radius: 0;
    color: @text-color;
  }
}

.ant-select-selection-placeholder {
  opacity: 0.5;
}

.ant-popover-buttons button {
  margin-left: 0;
}

.ant-result-title {
  line-height: 1.4;
  font-weight: bold;
  margin-bottom: 5px;
}
