@import '../../../../../styles/variables.less';

#record-details-liveness-component {
  height: 100vh;

  .header-record-details {
    height: 64px;
    left: 0px;
    top: 0px;
    position: fixed;
    background: #fff;

    z-index: 999;
    border-bottom: 1px solid #bdbdbd;
    font-size: 1rem;

    padding: 16px;

    .copy-record-details-link-button {
      padding: 0 8px;
      background-color: transparent;
    }

    .strict-eval-tag {
      background: #f2dff4;
      color: #4b1052;
      font-size: 14px;
      font-weight: 700;
      height: 30px;
      
      .icon {
        color: #c431ac;
        display: flex;
        align-items: center;
        margin-left: 0px;
        margin-right: 5px;
      }
    }
  }

  .body-record-details {
    padding: 88px 24px 0 24px;
    overflow-x: hidden;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;

    .img-liveness {
      width: 488px;
      height: 481px;

      .ant-tabs-nav {
        display: none;
      }
    }

    .video-liveness {
      width: 488px;
      height: 481px;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-size: 24px;
      }
    }
  }

  .footer-record-details {
    height: 64px;
    position: fixed;
    left: 0px;
    bottom: 0px;
    z-index: 999;
    border-top: 1px solid #bdbdbd;

    display: flex;
    align-items: center;
    justify-content: center;

    .btn-reprove {
      background-color: @danger-color;
      text-shadow: none;
      width: 97px;

      span {
        color: @white-color;
      }

      &:hover,
      &:active,
      &:focus {
        background-color: @danger-secondary-color !important;
        color: @white-color !important;
        span {
          color: @white-color !important;
        }
      }
    }
  }
}

.liveness-dropdown {
  border-radius: 8px;

  .liveness-info {
    width: 416px;
    padding: 16px 16px 0 16px;

    .ant-collapse-item {
      border-radius: 8px;
      margin-bottom: 16px;
      background: @gray-light-color;
      padding: 12px;
      border: 1px solid @gray-mid-color;

      .ant-collapse-header {
        display: flex;
        align-items: center;
        font-size: 1rem;
        color: @text-color;
      }

      .ant-collapse-content {
        .ant-collapse-content-box {
          padding-bottom: 0px;

          .card-body {
            .card-title {
              margin-bottom: 16px;
            }

            .ant-divider {
              margin: 0px 0px 20px 0px;
            }
          }

          .card-info {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            svg {
              color: @primary-color !important;
            }

            &.approved {
              svg {
                color: @success-color !important;
              }
            }

            &.reproved {
              svg {
                color: @danger-color !important;
              }
            }
          }

          .btn-link {
            padding: 0px;
            margin-top: 4px;

            span {
              color: @primary-color;
            }
          }
        }
      }

      .ant-divider {
        color: @gray-mid-color;
      }
    }
  }
}
