@import '../../../../../../styles/variables.less';

.queue-filters-modal-component {
  .ant-modal {
    width: 1090px !important;

    .ant-modal-body {
      padding: 56px;
    }

    .modal-header {
      .title {
        margin-bottom: 4px;
      }

      .subtitle {
        font-size: 16px;
      }
    }

    .modal-body {
      .ant-row {
        margin-top: 40px;

        .ant-col:first-child,
        .ant-col:last-child {
          .title {
            margin-top: 28.13px;
          }
        }

        .ant-col .title {
          font-size: 24px;
          line-height: 28.13px;
          margin-bottom: 24px;
        }

        .ant-col .filter-box {
          &.set-opacity {
            .title {
              color: @gray-color;
            }

            .ant-select .ant-select-selector {
              border: 1px solid rgba(@dark-gray-color, 16%);
              background-color: @white-color;

              .ant-select-selection-placeholder {
                color: rgba(@dark-gray-color, 16%);
              }
            }
          }
        }

        .ant-col .ant-checkbox {
          .ant-checkbox-inner {
            border-radius: 30% !important;
          }
        }
      }
    }

    .modal-footer {
      margin-top: 32px;

      .ant-btn-danger,
      .ant-btn-default {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 32px;
      }

      .ant-btn-danger {
        width: 100px;
      }

      .ant-btn-default {
        width: 137px;
      }
    }
  }
}
