@import '../../../../../../../styles/variables.less';

.field-presence-check-component {
  .field-presence-check-icon {
    height: 32px;
    width: 32px;
    background-color: @primary-color;
    border-radius: 4px 0px 0px 4px;
    color: @white-color;
  }

  .field-presence-check-name {
    background-color: #f5f5f5;
    padding: 8px 14px 8px 7px;
    border-radius: 0px 4px 4px 0px;
    border: 1px solid @gray-light-color-1;
    height: 32px;
    font-size: 0.875rem;
  }
}
