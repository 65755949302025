@import '../../styles/variables.less';

.custom-modal-component {
  .ant-modal {
    width: 301px !important;
    height: 211px !important;

    .ant-modal-content {
      display: flex;
      align-items: center;
      justify-content: center;

      .img-modal {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 68px;
        height: 68px;
        margin-top: -50px;
        margin-left: 63px;
        background: @white-color;
        border-radius: 34px;

        img {
          width: 42px;
          height: 42px;
          margin: 9px;
        }
      }

      .body-modal {
        max-width: 187px;
        max-height: 56px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 20px 0 32px 0;
        text-align: center;

        h1 {
          font-size: 16px;
        }
        span {
          font-size: 12px;
          font-weight: 700;
          color: lighten(@black-color, 56%) !important;
        }
      }
      .btn-ok {
        width:195px;
      }
    }
  }
}
