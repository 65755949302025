.zoomed-image-component {
  overscroll-behavior: none;
  position: relative;
  overflow: hidden;
  cursor: default;

  .zoomed-image-menu, .zoomed-image-label {
    span {
      font-size: 0.75rem !important;
    }
  }

  .scroll-container {
    height: 100%;
    overflow: auto;
    text-align: center !important;

    img {
      padding: 0 !important;
      max-width: 5000px;
      max-height: 5000px;
      object-fit: contain;

      &.rendering-pixelated {
        image-rendering: pixelated;
      }
    }

    .ant-btn {
      margin-bottom: 20px;
    }
  }
}

.scale:hover {
  img:hover {
    cursor: none;
  }

  .hair {
    float: left;
    position: absolute;
    background-color:red;
    z-index: 10;
    pointer-events: none;
  }

  #crosshair-h {
    width: 100%;
    height:1px;
    margin-top: -8px;
    visibility: visible;
  }
  #crosshair-v {
    height:100%;
    width:1px;
    margin-left: -8px;
    visibility: visible;
  }
}
