@import '../../../../styles//variables.less';

#custom-filter-component {
  .double-check-filter {
    padding: 0px;
    margin-top: 40px;
  }

  .customers-filter-panel {
    .ant-select-selection-item {
      max-width: 200px;
    }
  }

  .ant-select-selection-item {
    line-height: unset !important;
  }

  .ant-select-selection-overflow-item {
    max-width: unset;
  }

  .ant-collapse-header-text {
    font-size: 1.5rem;
  }

  .ant-radio {
    margin-top: -5px;
  }
  .ant-collapse-arrow {
    right: 16px;
    left: auto;
  }

  .ant-collapse {
    border: none;
    margin-top: 28px;

    .ant-collapse-header {
      font-size: 1rem;
      font-weight: 700;

      padding: 12px 16px;
      display: flex;
      align-items: center;
    }

    .ant-collapse-item {
      border: none;

      .ant-collapse-content {
        border: none;
        background-color: transparent;
        overflow: initial;

        .ant-collapse-content-box {
          padding-top: 0;
        }
      }
    }
  }

  .btn-filters {
    button {
      width: 291px;
      font-weight: 500;
      margin-top: 16px;
    }

    button:first-child {
      margin-top: 40px;
    }
  }

  .specific-reasons-select {
    .ant-select-selection-item {
      max-width: 250px;
    }
  }

  .ant-row {
    margin-left: 0;
  }
}

.review-filter-tooltip {
  .ant-tooltip-arrow-content {
    background-color: #323232;
    --antd-arrow-background-color: #323232;
  }

  .ant-tooltip-inner {
    text-align: center;
    background-color: #323232;
  }
}

.month-picker-without-year {
  .ant-picker-header > button.ant-picker-header-super-prev-btn,
  button.ant-picker-header-super-next-btn,
  button.ant-picker-year-btn {
    display: none !important;
  }
}
.range-picker-custom {
    height: 32px !important;
}

.ant-picker-date-range-wrapper {
  .ant-btn-primary {
    width: 90px;
    height: 30px;
    font-size: 1rem;
    font-weight: 400;
    color: @white-color !important;
    background-color: @primary-color;
    text-shadow: none;
    border: none;
    border-radius: 100px;

  &:hover,
    &:active,
    &:focus {
      background-color: @secondary-color;
      color: @white-color;
    }
  }
}
