@import '../../../../../../../styles/variables.less';

#reprove-document-component {
  padding: 24px 40px;

  form {
    width: 100%;

    .ant-select:not(.ant-select-disabled) .ant-select-selector{
      border-color: none;
    }

    .ant-select-selection-item {
      line-height: unset !important;

      .ant-select-selection-item-remove {
        margin-top: 2px;
      }
    }

    .ant-select-selection-item-content {
      font-size: 0.75rem;
    }

    label {
      color: @text-color;
    }

    div {
      border-color: none;
      color: @danger-color;
    }

    .footer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      .btn-reprove {
        background-color: @danger-color;
        text-shadow: none;
        width: 97px;

        span {
          color: @white-color;
        }

        &:hover,
        &:active,
        &:focus {
          background-color: @danger-secondary-color !important;
          color: @white-color !important;
          span {
            color: @white-color !important;
          }
        }
      }
    }
  }
}
