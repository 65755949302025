@import '../../../../../../../styles/variables.less';

.field-details-list-component {
  .field-details-list-label {
    font-size: 0.75rem;
  }

  .field-details-list-box {
    margin-top: 4px;
    padding: 4px;
    background-color: #f5f5f5;
    flex-wrap: wrap;
    gap: 4px;
    border-radius: 4px;
    border: 1px solid @gray-light-color-1;
    min-height: 34px;

    .field-detail-tag {
      .middle-dot {
        font-size: 8px;
        margin-right: 8px;
      }

      background-color: @primary-color;
      font-weight: 500;
      color: @white-color;
    }
  }
}
