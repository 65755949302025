//global style variables
@dark-theme-border-color: @dark-gray-color;
@dark-theme-text-color: @white-color;

/*Base Styles*/
body.dark-theme {
  background: @dark-color !important;
  color: @dark-theme-text-color;
}

.dark-theme {
  .text-dark {
    color: @dark-theme-text-color !important;
  }

  #home-page-content {
    background-image: url('../assets/images/stars.svg') !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  #login-component {
    background-color: @black-color !important;
    span {
      color: @dark-theme-text-color !important;
    }

    .loginGoogle {
      background: transparent !important;
    }
  }

  #header-component {
    header {
      border-bottom: 1px solid @light-dark-gray-color;

      .nav-link.active {
        border-bottom: 3px solid rgba(@dark-theme-border-color, 40%);
      }

      a {
        color: @dark-theme-text-color !important;
        transition: none !important;
      }

      transition: none !important;
    }
  }

  #ocr-details-component {
    .ant-form-item-control-input-content {
      .custom-input {
        background-color: @light-dark-gray-color !important;
        border: 1px solid @light-dark-gray-color !important;
      }
    }
  }

  #record-details-liveness-component,
  #record-details-component {
    background-color: @black-color !important;
    .header-record-details {
      background: @dark-color!important;
      color: @dark-theme-text-color !important;

      span {
        color: @dark-theme-text-color !important;
      }
    }

    .header-record-details {
      border-bottom: 1px solid @light-dark-gray-color !important;

      .strict-eval-tag {
        background: #c431ac;
      }
    }

    .footer-buttons {
      background: @dark-color!important;
      color: @dark-theme-text-color !important;
      border-top: 1px solid @light-dark-gray-color !important;
    }
  }

  #record-details-liveness-component {
    .footer-record-details {
      background-color: #262626;
      border-top: 1px solid @light-dark-gray-color !important;
    }
  }

  #record-details-component {
    .record-details-cards {
      background: @dark-color!important;
      color: @dark-theme-text-color !important;
      border-color: @light-dark-gray-color !important;

      .ant-collapse-item {
        background-color: @black-color !important;
        .card-title {
          color: @dark-theme-text-color !important;
        }
      }

      .ant-input-clear-icon {
        svg {
          color: @dark-gray-color;
        }
      }

      &.documentscopy {
        .avaliation-international-type-collapse {
          form {
            input,
            .input-item-box {
              border-color: @grey-8;
            }

            .input-item {
              .input-item-label {
                color: @white-color;
              }

              .input-item-box {
                background-color: @black-color;
              }
            }

            .field-presence-check-component {
              .field-presence-check-name {
                background-color: @black-color;
                border-color: @grey-8;
                color: @white-color;
              }
            }

            .field-details-list-component {
              .field-details-list-label {
                color: @white-color;
              }

              .field-details-list-box {
                background-color: @black-color;
                border-color: @grey-8;
              }
            }
          }

          #document-identification-component {
            .document-identification-title {
              background-color: rgba(@primary-color, 30%);
            }

            .country-info {
              border-color: @grey-8;

              .country-code {
                color: @white-color;
              }
            }
          }

          #document-information-component {
            .rate-meter {
              .ant-rate-star-full {
                .anticon-star {
                  svg {
                    color: yellow;
                  }
                }
              }
            }
          }
        }
        .tags-header {
          margin: 10px;
          display: flex;
          justify-content: center;
          .flex-eval-tag {
            background: #32cd32;
            font-weight: bold;
            font-size: 1rem;
            .icon {
              display: flex;
              align-items: center;
              margin: auto 5px;
            }
          }
        }
      }
    }

    #international-documents-confirmation-component {
      .zoomed-image-component.review-document-zoomed-image-component {
        .zoomed-image-label {
          background-color: rgba(@primary-color, 75%) !important;
        }
      }
    }
  }

  .zoomed-image-component {
    background: rgba(@white-color, 8%) !important;
    border-color: @light-dark-gray-color !important;

    .zoomed-image-label {
      background-color: rgba(0, 0, 0, 0.5) !important;
    }

    .zoomed-image-menu {
      background-color: rgba(0, 0, 0, 0.5) !important;
      color: @white-color !important;
      border-color: @black-color !important;

      .ant-menu-submenu-arrow {
        &:after,
        &:before {
          background: @white-color !important;
        }
      }
    }
  }

  .zoomed-image-submenu {
    .ant-menu {
      background-color: @black-color !important;

      .zoomed-image-menu-item {
        color: @dark-gray-color !important;
      }
    }
  }

  #subheader-operations-groups-component {
    background-color: @black-color !important;
  }

  .card-info,
  .card-footer {
    svg {
      color: @dark-theme-text-color !important;
    }
  }

  .liveness-dropdown {
    background: @dark-color !important;

    .liveness-info {
      .ant-collapse-item {
        background: @black-color !important;
      }
    }
  }

  .ant-collapse-item:not(.danger-border, .primary-border, .warning-border, .success-border) {
    border-color: @light-dark-gray-color !important;
  }

  .view-more-details-component {
    background: linear-gradient(to bottom, transparent, @dark-color) !important;

    svg {
      color: rgba(@white-color, 50%) !important;
    }
  }

  a[disabled],
  .ant-btn[disabled],
  .ant-radio-button-wrapper-disabled:not(.ant-radio-button-wrapper-checked) {
    color: @dark-gray-color;
    background: transparent !important;
    border-color: @dark-gray-color;
  }

  .ant-radio-button-wrapper-disabled {
    svg {
      color: @dark-gray-color;
    }
  }

  .ant-radio-button-wrapper,
  .ant-radio-button {
    border-color: @dark-gray-color;
  }
  .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) {
    background: transparent !important;
  }

  a,
  .ant-btn-text {
    color: @dark-theme-text-color;

    .ant-input {
      background: transparent !important;
    }
  }

  .btn-icon {
    background: transparent !important;
  }

  .user-dropdown .ant-divider {
    border-top-color: @light-dark-gray-color !important;
  }

  .display-rules {
    color: @dark-theme-text-color !important;

    .header-divider{
      background-color: @white-color !important;
    }
  }

  .ant-list-item, .ant-list-header {
    color: @dark-theme-text-color !important;
  }

  .ant-layout-header,
  .gx-header-horizontal {
    background: @black-color !important;
    color: @dark-theme-text-color;
  }

  .ant-table-thead > tr > th {
    border-bottom-width: 1px !important;
    border-bottom-color: @dark-theme-border-color !important;
  }

  .ant-table-tbody > tr > td {
    border-bottom-width: 2px !important;
    border-bottom-color: @light-dark-gray-color !important;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr.ant-table-row:focus > td,
  .ant-table-cell-row-hover {
    background-color: rgba(@white-color, 8%) !important;
  }

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6,
  & .h1,
  & .h2,
  & .h3,
  & .h4,
  & .h5,
  & .h6,
  h1.ant-typography,
  .ant-select-selection-item,
  .ant-empty-description,
  .custom-page-title .subtitle,
  .ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input,
  .ant-typography,
  .ant-collapse > .ant-collapse-item > .ant-collapse-header,
  .ant-radio-wrapper,
  .option-title,
  .option-subtitle,
  .card-info,
  .ant-breadcrumb-link,
  .ant-breadcrumb-separator,
  .label,
  .model,
  p {
    color: @dark-theme-text-color !important;
  }

  .countdown {
    div {
      background: @dark-color !important;
    }

    span {
      background-color: @light-dark-gray-color !important;
    }
  }

  .ant-picker-input > input {
    color: @dark-theme-text-color !important;
  }

  .ant-picker-time-panel-column
    > li:not(.ant-picker-time-panel-cell-selected)
    .ant-picker-time-panel-cell-inner {
    color: @dark-theme-text-color !important;
  }

  .ant-picker-header,
  .ant-picker-panel,
  .ant-picker-time-panel,
  .ant-picker-time-panel-column:not(:first-child),
  .ant-empty {
    border-color: @light-dark-gray-color !important;
  }

  .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header {
    color: @light-dark-gray-color !important;
  }

  svg:not(.rateMeter) {
    color: @white-color;
  }

  .ant-form-item-control-input-content {
    .ant-input:not(.ant-input-text) {
      color: @dark-theme-text-color;
      background: transparent !important;
    }

    .custom-input {
      .ant-input {
        background-color: transparent !important;
        border: none !important;
      }
    }
  }

  .ant-picker,
  .ant-picker-panel,
  .ant-picker-panel-container {
    background-color: @black-color;
    color: @dark-theme-text-color !important;
    border-color: @light-dark-gray-color !important;
  }

  .ant-picker-header {
    button {
      color: @dark-theme-text-color !important;
    }
    color: @dark-theme-text-color !important;
  }

  .ant-input-affix-wrapper {
    background-color: @light-dark-gray-color !important;
  }

  .ant-select:not(.ant-select-borderless) .ant-select-selector,
  .ant-input-number,
  .ant-input-text {
    background-color: @light-dark-gray-color !important;
    border: 1px solid @light-dark-gray-color !important;
    color: @dark-theme-text-color !important;
  }

  .ant-input[disabled] {
    color: darken(@dark-theme-text-color, 40%) !important;
  }

  .ant-form-vertical .ant-form-item-label > label {
    color: @dark-theme-text-color !important;
  }

  .user-dropdown-trigger {
    span {
      color: @dark-theme-text-color !important;
    }
  }

  .ant-dropdown,
  .ant-dropdown-menu,
  .ant-select-dropdown,
  .ant-select-item,
  .ant-modal-content {
    .img-modal {
      background: @black-color !important;
    }

    background-color: @black-color;
    color: @dark-theme-text-color !important;

    svg,
    a,
    span:not(.ant-tag) {
      color: @dark-theme-text-color;
    }
  }

  .ant-dropdown-menu-item:not(.gx-text-danger) {
    svg {
      color: @dark-theme-text-color !important;
    }
  }

  .ant-card-bordered {
    background-color: transparent !important;
    border: none;

    .ant-card-head {
      .ant-card-head-title {
        color: @white-color !important;
      }
    }

    .ant-card-body {
      background-color: @black-color;
      border: 1px solid @light-dark-gray-color !important;
      border-radius: 8px;

      .card-info,
      .card-title {
        color: @dark-theme-text-color !important;
      }

      .card-footer {
        border-top: 1px solid @light-dark-gray-color !important;

        span,
        button,
        b {
          color: @dark-theme-text-color !important;
        }
      }
    }
  }

  & th,
  & td,
  & .column-title,
  & .ant-table-column-title {
    color: @dark-theme-text-color !important;
    border-top-color: @dark-theme-border-color !important;

    svg,
    a,
    span {
      color: @dark-theme-text-color !important;
    }
  }

  .ant-select-selection-placeholder,
  .ant-input::placeholder {
    color: @gray-color !important;
    opacity: 1;
  }

  .ant-input-affix-wrapper {
    transition: none;
  }

  .custom-search-input,
  .select-custom-icon {
    border-bottom: 1px solid @light-dark-gray-color !important;
  }

  .ant-divider {
    border-top-color: @light-dark-gray-color;
  }

  .ant-pagination > li,
  .ant-pagination-jump-next {
    color: @dark-theme-text-color !important;

    a,
    span {
      color: @dark-theme-text-color !important;
    }
  }

  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled) {
    span {
      color: @dark-theme-text-color !important;
    }
    .ant-checkbox-inner {
      background-color: transparent !important;
      border: 1px solid @light-dark-gray-color !important;
    }
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: @primary-color !important;
      }
    }
  }

  .ant-checkbox {
    background-color: @gray-light-color-1 !important;
    border-radius: 100%;

    &.ant-checkbox-checked {
      background-color: transparent !important;
    }
  }

  .custom-tag {
    color: @primary-color !important;
  }

  .ant-checkbox-wrapper-disabled {
    .ant-checkbox-inner {
      background-color: @dark-color !important;
      border: none;
    }
    .ant-checkbox-inner::after {
      border-color: @white-color !important;
    }
  }

  .ant-table-column-sort {
    background-color: transparent !important;
  }

  .ant-table-thead th.ant-table-column-has-sorters {
    transition: none !important;
  }

  .ant-table-thead th.ant-table-column-sort {
    background-color: @black-color !important;
  }

  .user-dropdown .privacy-policies-terms-of-use div a {
    color: @dark-theme-text-color !important;
  }

  .user-dropdown .privacy-policies-terms-of-use div a:last-child::before {
    background: @dark-theme-text-color !important;
  }

  .info-modal {
    #info {
      .ant-carousel .slick-prev::before,
      .ant-carousel .slick-next::before {
        color: @white-color;
        background-color: rgba(@white-color, 8%) !important;
      }
    }
  }

  #copy-to-clipboard-button-component:hover {
    svg {
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      color: @primary-color !important;
    }
  }

  #queue-empty-component {
    .btn-queue-empty {
      a {
        color: @white-color;
      }
    }
  }

  .rc-virtual-list-scrollbar-thumb {
    background: @dark-gray-color !important;
  }

  .ant-radio-button-wrapper {
    &:not(.ant-radio-button-wrapper-checked) {
      background-color: @black-color !important;
    }
  }

  .toast-message {
    .ant-message-notice-content {
      background-color: @black-color !important;

      .ant-message-custom-content {
        span {
          color: @white-color !important;
        }
      }
    }
  }

  #operations-groups-page,
  #operations-groups-users-list-component {
    .tabs {
      a {
        &.active {
          color: @white-color;
        }
      }
    }
  }

  #queue-component {
    #queue-cards-component {
      .ant-card {
        .ant-card-head {
          background-color: @black-color;
          border: 1px solid rgba(@dark-gray-color, 24%);
        }

        .ant-card-body {
          border-top-right-radius: 0px;
          border-top-left-radius: 0px;

          .card-row {
            color: @gray-color;

            > span + span,
            > div + span {
              color: @white-color;
            }
          }
        }

        &.filters-card {
          .ant-card-head {
            .ant-card-extra {
              svg {
                color: @white-color;
              }
            }
          }

          .ant-card-body {
            .card-row {
              color: rgba(@dark-gray-color, 16%);

              &.filter-is-enabled {
                span {
                  color: @gray-color;
                }
              }
            }
          }
        }
      }
    }

    #queue-list-component {
      tbody {
        td {
          border-bottom: 1px solid transparent !important;
        }

        .ant-table-row {
          &:has(.red-background) {
            &:not(:hover),
            &:hover {
              td {
                background-color: rgba(@danger-color, 8%) !important;
              }
            }
          }

          &:has(.yellow-background) {
            &:not(:hover),
            &:hover {
              td {
                background-color: rgba(@warning-color, 8%) !important;
              }
            }
          }

          &:has(.grey-background) {
            &:not(:hover),
            &:hover {
              td {
                background-color: rgba(@gray-light-color-1, 8%) !important;
              }
            }
          }
        }
      }
    }
  }

  .queue-filters-modal-component {
    .filter-box {
      .ant-select-selector {
        .ant-select-selection-placeholder {
          svg,
          span {
            color: @dark-gray-color;
          }
        }
      }

      &.set-opacity {
        .title {
          color: @dark-gray-color !important;
        }

        .ant-select-selector {
          border-color: rgba(@dark-gray-color, 16%) !important;

          .ant-select-selection-placeholder {
            svg,
            span {
              color: rgba(@dark-gray-color, 16%);
            }
          }
        }
      }
    }
  }

  .custom-filter-select-dropdown {
    .ant-select-item-option-selected {
      background-color: rgba(@primary-color, 8%);

      .ant-select-item-option-content,
      svg {
        color: @primary-color;
      }
    }
  }
}
