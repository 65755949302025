@import '../../../../styles/variables.less';

#queue-cards-component {
  font-size: 1rem;

  .ant-card {
    .ant-card-head {
      padding: 0 1rem;

      .ant-card-head-title {
        padding: 1rem 0;
      }
    }

    .ant-card-body {
      font-size: 1rem;
      font-weight: 700;
      padding: 1rem;
      line-height: 1.5rem;

      .card-row {
        &:not(:last-child) {
          margin-bottom: 1rem;
        }

        > span + span,
        > div + span {
          color: @black-color;
        }

        .rect-icon {
          width: 1.5rem;
          height: 1.5rem;

          svg,
          rect {
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 4px;
          }

          rect {
            &.fill-grey {
              fill: @gray-light-color-1;
            }

            &.fill-warning {
              fill: @warning-color;
            }

            &.fill-danger {
              fill: @danger-color;
            }
          }
        }

        .dark-red {
          svg, span {
            color: #AC1434;
          }

          svg {
            width: 1.5rem;
            height: 1.5rem;
          }
        }

        .rect-icon,
        svg {
          margin-right: 0.5rem;
        }
      }
    }

    &.filters-card {
      .ant-card-head {
        .ant-card-extra {
          padding: 0;

          button {
            padding: 0;
            border: none;

            svg {
              display: flex;
              align-items: center;
              justify-content: center;
              color: @black-color;
            }
          }
        }
      }

      .ant-card-body {
        .card-row {
          color: @gray-light-color-1;

          &.filter-is-enabled {
            span {
              color: @dark-gray-color;
            }

            svg {
              margin: 0px;
              color: @primary-color !important;
            }
          }
        }
      }
    }
  }
}
