@import '../../../../../styles/variables.less';

#record-details-component {
  .record-details-cards {
    .body-record-details-review {
      margin: 24px 24px 110px 24px;
      overflow-x: hidden;

      .ant-radio-button-wrapper-disabled:not(.ant-radio-button-wrapper-checked) {
        background-color: @white-color;
      }
    }
  }
}
