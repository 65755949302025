@import '../../../../styles/variables.less';

#alert-error-record-load {
  width: 100%;
  margin: 50px auto;
}

#record-details-component {
  height: 100vh;

  .custom-col {
    margin-top: -15px;
  }
  .ant-form-item-label {
    label {
      font-weight: 700;
      color: @text-color;
      font-size: 0.75rem;
    }
  }

  .field-mandatory {
    label {
      font-weight: 900 !important;
      color: @danger-color !important;
    }
  }

  .record-details-cards {
    overflow: auto;
    background: @white;
    border-radius: 10px;
    height: 100vh;

    .header-record-details {
      left: 0px;
      top: 0px;
      position: fixed;
      background: #fff;
      height: fit-content;
      padding: 10px;
      width: 33vw;
      z-index: 999;
      border-bottom: 1px solid #bdbdbd;
      font-size: 0.8rem;

      span {
        color: @black-color;
        font-weight: 500;
      }

      .details {
        margin: 8px 24px 0 24px;

        .clock-icon {
          gap: 4px;
        }

        .copy-record-details-link-button {
          padding: 0 8px;
          background-color: transparent;
        }
      }
    }

    .ant-collapse-header {
      display: flex;
      align-items: center;
      font-size: 0.875rem;
    }

    .body-record-details {
      .card-info {
        font-size: 1rem;
        color: @text-color;
        display: flex;
        align-items: center;
        line-height: 1;
        margin: 15.5px 0 0 16px;

        &.approved {
          svg {
            color: @success-color !important;
          }
        }

        &.reproved {
          svg {
            color: @danger-color !important;
          }
        }
      }

      .card-title {
        font-size: 1rem;
        font-weight: 700;
        margin: 16px 0 8.5px 16px;
        line-height: 1.2;
        color: @text-color;
        flex: 1;
      }

      .card-title-digital-manipulation {
        font-size: 0.9rem;

        margin: 16px 0 8.5px 16px;
        line-height: 1.2;
        color: @text-color;
        flex: 1;
      }

      .danger-border {
        border-color: @danger-color;
      }

      .primary-border {
        border-color: @primary-color;
      }

      .success-border {
        border-color: @primary-color;
      }

      .warning-border {
        border-color: @warning-color;
      }
    }

    .footer-record-details {
      position: fixed;
      left: 0px;
      bottom: 0px;
      z-index: 999;

      .view-more-details-component {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        background: linear-gradient(to bottom, transparent, white);

        svg {
          color: rgba(@black-color, 50%);
        }
      }

      .footer-buttons {
        background: #fff;
        border-top: 1px solid #bdbdbd;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 24px 24px;

        .btn-finished-true {
          height: 110px;
        }

        .btn-finished-false {
          height: 90px;
        }

        .btn-all-rules-correct {
          width: 214px;
        }

        .btn-finished {
          width: 110px;
        }

        .btn-reprove {
          width: 110px;
          border: 1px solid @danger-color;
          border-radius: 100px;
          padding: 0 16px !important;
          height: 36px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: @danger-color;
          font-size: 1rem;
          font-weight: 700;

          &:hover {
            background-color: @danger-color;
            color: @white-color;
          }
        }
      }
    }

    &:has(.image-is-hovered) {
      overflow-y: hidden;

      &:not(&:has(.avaliation-international-type-collapse)) {
        .body-record-details {
          padding-right: 28px;
        }
      }
    }
  }

  .document-col {
    text-align: center;
    height: 100%;
  }

  .ant-tabs {
    &.ant-tabs-top > .ant-tabs-nav {
      .ant-tabs-tab {
        padding: 8px 0;
        margin: 0 15px 0 0;
      }

      &::before {
        border-bottom: none;
      }
    }
    .ant-tabs-tab {
      &.ant-tabs-tab-active {
        color: @text-color;
      }
      &:hover {
        color: lighten(@text-color, 10%);
      }
    }
  }

  @media (min-width: 200px) and (max-width: 1350px) {
    .ant-anchor {
      margin-top: 110px;

      .ant-anchor-link-title {
        font-size: 0.6rem;

        &.ant-anchor-link-title-active {
          font-weight: 700;
          font-size: 0.75rem;
        }
      }
    }
  }

  @media (min-width: 1200px) {
    .ant-anchor {
      margin-top: 80px;
    }
  }

  .model {
    margin: 24px 0px 32px 0px;
    color: @black-color;
  }

  .btn-link > span {
    color: @primary-color;
  }

  #avaliation-without-page {
    p {
      margin: 24px;
      color: @text-color;
      word-wrap: normal;
    }
  }
}

#document-confirmation-component {
  position: fixed;
  margin: 16px !important;
  width: 64vw;
  font-size: 1rem;
  height: 100vh;
  overflow: auto;

  .img-error {
    position: relative;
    top: 25%;
    left: 25%;
  }

  h1 {
    font-size: 1.1rem;
  }

  > .slick-dots li button {
    width: 6px;
    height: 6px;
    border-radius: 100%;
  }

  > .slick-dots li.slick-active button {
    width: 7px;
    height: 7px;
    border-radius: 100%;
    background: red;
  }

  .image-tabs {
    margin-top: -15px;
  }

  .ant-tabs-tab-btn {
    font-size: 1rem;
    font-weight: normal;
  }

  .custom-tooltip {
    white-space: nowrap;
  }

  .documents-confirmation-container {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    gap: 0 16px;
  }
}

#poc-client-message {
  color: @danger-color !important;
}

#type-document-selector,
#country-document-selector {
  padding: 8px 0 8px 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;

  .label {
    white-space: nowrap;
    margin-right: 8px;
    font-size: 1rem;
    font-weight: 700;
    color: @black-color;
  }

  .ant-select {
    width: 100%;
  }

  .ant-select-selector {
    display: flex;
    align-items: center;
  }

  .ant-select-selection-item {
    height: 24px;
    margin-right: 20px;
    font-size: 0.8rem;
    background: @primary-color;
    padding-left: 0.75rem;
    padding-right: 0.6rem;
    border-radius: 100px;
    color: @white-color;
    border: none;
    line-height: unset !important;

    .ant-select-selection-item-content {
      margin-right: 0.5rem;
    }
  }

  .ant-select-selection-item-remove {
    line-height: 1;

    i {
      font-size: 0.75rem;
      color: #fff;
    }
  }
}

#country-document-selector {
  margin-bottom: 16px;
}

#back-home-button {
  &.back-home-enabled {
    background-color: @primary-color !important;
    svg {
      color: @white-color;
    }
  }
}

.all-rules-correct-modal {
  .ant-modal-body {
    .ant-modal-confirm-btns {
      .ant-btn-default > span {
        color: @primary-color;
      }

      .ant-btn-primary > span {
        color: @white-color;
      }
    }
  }
}
