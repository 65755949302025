#validation-issue-year-uf-component {
  padding: 14px 20px 20px 24px;

  form {
   width: 100%;

    .ant-form-item-label>label {
      font-size: 0.75rem;
    }
  }

  .issue-date {
    width: 9rem;
    height: 35px;
  }

  .issue-state {
    width: 3.45rem;
    height: 35px;
  }

  .ant-btn span {
    width: 101px;
  }

  .uppercase-input {
    text-transform: uppercase;
  }
}
