@import '../../../../../../styles/variables.less';

.ant-collapse-header {
  margin-bottom: 4px;
}

#document-information-component,
#specimen-information-component {
  form {
    .custom-inputs {
      margin-bottom: 0px;

      &:not(:first-child) {
        margin-top: 16px;
      }

      .ant-form-item {
        margin-bottom: 0px;

        &:not(:last-child) {
          margin-right: 8px;
        }

        .ant-form-item-label {
          padding-bottom: 0px;

          label {
            font-weight: 400;
          }
        }

        // TODO test if this is necessary
        // .ant-form-item-control {
        //   max-height: 32px;
        // }
      }
    }

    .custom-input {
      padding: 8px;
      border-radius: 4px;
      border: 1px solid #d9d9d9;
      height: 32px;

      &:disabled {
        cursor: text;
      }
    }
  }
}
