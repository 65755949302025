@import '../../../../styles/variables.less';

#queue-list-component {
  table {
    border-spacing: 0 4px;

    thead {
      th.ant-table-cell {
        span.column-title {
          color: @black-color;
          font-size: 14.5px;
          font-weight: 500;
        }
      }
    }

    tbody {
      .ant-table-row {
        td:first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }

        td:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;

          padding: 0;
        }

        &:has(.red-background) {
          &:not(:hover), &:hover {
            td {
              background-color: #F5D5DC;
            }
          }
        }

        &:has(.yellow-background) {
          &:not(:hover), &:hover {
            td {
              background-color: #F9EDDB;
            }
          }
        }

        &:has(.grey-background) {
          &:not(:hover), &:hover {
            td {
              background-color: #F2F2F2;
            }
          }
        }

        &:hover {
          td {
            background-color: @gray-light-color;
          }
        }

        .view-groups {
          svg {
            margin-left: 0.625rem;
          }
        }

        .record-details-link {
          svg {
            color: @black-color;
          }
        }
      }
    }
  }
}

.record-details-tooltip {
  padding-bottom: 2px;

  .ant-tooltip-content {
    .ant-tooltip-arrow {
      .ant-tooltip-arrow-content::before {
        background-color: rgba(0, 0, 0, 64%);
        --antd-arrow-background-color: rgba(0, 0, 0, 64%);
      }
    }

    .ant-tooltip-inner {
      background-color: rgba(0, 0, 0, 64%);
      backdrop-filter: blur(2px);
    }
  }
}

.operations-group-popover {
  padding-bottom: 0px;

  .ant-popover-content {
    .ant-popover-arrow {
      .ant-popover-arrow-content::before {
        background-color: rgba(0, 0, 0, 64%);
      }
    }

    .ant-popover-inner {
      background-color: rgba(0, 0, 0, 64%);
      backdrop-filter: blur(2px);

      .ant-popover-inner-content {
        color: @white-color;
        font-size: 0.875rem;
      }
    }
  }
}
