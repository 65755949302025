@import '../../../../../../../../../styles/variables.less';

.confirm-incorrect-analysis-modal {
  .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns {
    .ant-btn-default span {
      color: @black-color;
    }

    .ant-btn-primary span {
      color: @white-color;
    }
  }
}
