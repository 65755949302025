@import '../../styles/variables.less';

#header-component {
  &.gx-header-horizontal {
    &::before {
      display: none !important;
    }
  }

  &.gx-inside-header-horizontal {
    .ant-menu-item {
      padding: 0 0;
      margin: 0 7px;
    }
  }

  .gx-logo {
    margin-right: 45px;
  }

  .nav-link {
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 0.5px;

    &.active {
      border-width: 2px;
    }

    &:hover {
      color: @text-color;
    }
  }

  header {
    background-color: @white-color;
    border-bottom: solid 1px @gray-light-color-1;
  }

  .header-hide {
    top: -100px;
    max-height: 0px;

    transition: all 0.3s linear;
  }

  .header-show {
    top: 0px;
    max-height: 200px;

    transition: all 0.3s linear;
  }

 .ant-menu-horizontal > .ant-menu-item a  {
    color: @black-color;
    padding: 3px 3px 7px 3px;
  }

  .nav-link.active {
    border-bottom: 3px solid @menu-active-color;
  }

  .nav-link.unselected {
    border: none;
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after  {
    border: none
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item::after  {
    border: none
  }

  &:hover {
    border: none
  }


}
