@import '../../../../../styles/variables.less';

#record-details-component {
  .record-details-cards.documentscopy {
    width: 34vw;

    .header-record-details {
      width: 33vw;
      .span {
        align-content: center;
        font-size: 1.0rem;
        margin-left: 10px;
      }
    }
    .tags-header {
      margin: 10px;
      display: flex;
      justify-content: center;
      .flex-eval-tag {
        color: @dark-green;
        background: @light-green-color;
        font-weight: bold;
        font-size: 1rem;
        .icon {
          display: flex;
          align-items: center;
          margin: auto 5px;
        }
      }
      .strict-eval-tag {
        background: #f2dff4;
        color: #4b1052;
        font-size: 14px;
        font-weight: 700;
        height: 30px;
        
        .icon {
          color: #c431ac;
          display: flex;
          align-items: center;
          margin-left: 0px;
          margin-right: 5px;
        }
      }
      .requested-var-div {
        display: flex;
        align-content: center;
        span {
          font-size: 1.0rem;
          margin-left: 10px;
        }
      }
      
    }

    .body-record-details-evaluation {
      padding: 76px 24px 0 24px;
      overflow-x: hidden;
    }

    .footer-record-details {
      width: 33vw;

      .evaluation-progress-bar {
        margin-right: 8px;

        .ant-progress-steps-item {
          width: 4.375rem !important;

          &:first-child {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }

          &:last-child {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }
        }
      }
    }
  }
}

.confirm-finalization-modal {
  .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns {
    .ant-btn-default span {
      color: @primary-color;
    }

    .ant-btn-primary span {
      color: @white-color;
    }
  }
}
