@import '../../../../../../../styles/variables.less';

.queue-filters-modal-component {
  .ant-modal {
    .ant-select {
      padding: 0;

      .ant-select-selector {
        padding: 8px;
        border-radius: 4px;

        &:not(:has(.ant-select-selection-placeholder)) {
          border-color: @primary-color !important;
        }

        .ant-select-selection-placeholder {
          font-size: 16px;
          color: @dark-gray-color;

          svg {
            margin-right: 8px;
          }
        }

        .ant-select-selection-overflow {
          .ant-select-selection-search {
            margin-left: 2px;
          }

          .ant-select-selection-overflow-item {
            .ant-select-selection-item {
              height: 24px;
              border-radius: 28px;
              font-size: 12px;
              background: @primary-color;
              color: @white-color;
              font-weight: 500;
              padding: 2px 8px;
              margin: 2px 4px;
              border: none;

              .ant-select-selection-item-content {
                overflow: visible;
                margin-right: 8px;
              }

              .ant-select-selection-item-remove {
                svg {
                  margin: 0;
                  color: @white-color;
                }
              }
            }
          }
        }
      }
    }
  }
}

.custom-filter-select-dropdown {
  padding: 0px;

  .ant-select-item {
    padding: 8px 12px;

    &:first-child {
      padding-top: 12px;
      padding-bottom: 8px;
    }

    &:last-child {
      padding-top: 8px;
      padding-bottom: 12px;
    }

    &.ant-select-item-option-selected {
      color: @primary-color;
    }

    .ant-select-item-option-content {
      font-size: 16px;
      font-weight: 400;
    }

    .ant-select-item-option-state {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
