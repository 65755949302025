#record-details-component {
  .record-details-cards.pending-ocr {
    width: 60vw;

    .header-record-details {
      width: 59vw;
    }

    .body-record-details {
      width: 59vw;
    }

    .body-record-details-evaluation {
      padding: 76px 24px 0 24px;
      overflow-x: hidden;
    }

    .footer-record-details {
      width: 59vw;
    }
  }
}
