@import '../../styles/variables.less';

#sort-icon-component {
  margin-bottom: -11px;
  margin-left: 8px;

  svg {
    margin-top: -11px;
    width: 20px;
    height: 20px;

    &.sort-enabled {
      path {
        color: @primary-color;
        stroke: @primary-color;
      }
    }
  }
}
