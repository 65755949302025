#approve-rules,
#reprove-rules {
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0;

  div {
    padding: 0;
  }
  li {
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;

    svg {
      margin-right: 4px;
      width: 8px;
      height: 8px;
    }
  }
}

#approve-rules {
  color: #17521b;
  background-color: #0baa4329;

  li {
    color: #17521b;
  }
}

#reprove-rules {
  color: #521717;
  background-color: #e21b4529;

  li {
    color: #521717;
  }
}
