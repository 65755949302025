@import '../../../../../../../styles/variables.less';

.country-flag-avatar {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 29.62px;
    height: 21.38px;
  }
}

.spin-loading-flag {
  margin: 0px;
}
