@import '../../../../../../../../../../styles/variables.less';

.report-suspect-modal-component {
  .ant-modal {
    width: 600px !important;

    .modal-header {
      .title {
        margin-bottom: 4px;
        font-size: 24px;
      }
    }

    .modal-body {
      margin-top: 12px;

      span {
        font-size: 16px;
      }
    }

    .modal-footer {
      margin-top: 16px;

      .ant-btn-danger,
      .ant-btn-default {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 32px;
      }

      .ant-btn-danger {
        width: 100px;
      }

      .ant-btn-default {
        width: 137px;
      }
    }
  }
}

.confirm-report-suspect-modal {
  .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns {
    .ant-btn-default span {
      color: @black-color;
    }

    .ant-btn-primary {
      background-color: @danger-color !important;

      span {
        color: @white-color;
      }
    }
  }
}
