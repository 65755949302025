@import '../../../../../../../styles/variables.less';

#document-information-component {
  .input-item {
    .input-item-label {
      font-size: 0.75rem;
      color: rgba(0, 0, 0, 0.85);
      line-height: 14px;
    }

    .input-item-box {
      background-color: #f5f5f5;
      padding: 6px 4px 6px 4px;
      border-radius: 4px;
      border: 1px solid @gray-light-color-1;
      height: 32px;
      margin-top: 2px;
    }
  }
}
