@import '../../../../styles/variables.less';

.multi-select-primary {
  .ant-select-selection-overflow {
    min-height: 42px;
  }

  .ant-select-selection-item {
    height: 24px;
    font-size: 13px;
    background: @primary-color;
    color: @white-color;
    padding-left: 12px;
    padding-right: 9px;
    border-radius: 100px;
    border: none;

    .ant-select-selection-item-content {
      margin-right: 7px;
      overflow: visible;
    }
  }

  .ant-select-selection-item-remove {
    line-height: 1;

    svg {
      font-size: 12px;
      color: @white-color;
    }
  }
}

.hide-component {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s, opacity 0.3s;
}

.show-component {
  visibility: visible;
  opacity: 1;
}
