@import '../../styles/variables.less';

.ant-card {
  margin: 0;

  .ant-card-head {
    .ant-card-head-wrapper {
      .ant-card-head-title {
        font-size: 1.25rem;
        font-weight: 700;
        color: @black-color;
      }
    }
  }
}
